<template>
  <page-title
    class="q-mb-lg"
    :title="$t('account.social_account')"
  ></page-title>
  <q-intersection
    class="full-height default-shadow default-rounded bg-white full-height-intersection"
    transition="slide-up"
  >
    <Form
      class="q-gutter-md q-mt-lg"
      :initial-values="initialValues"
      :validation-schema="schema"
      @submit="onSubmit"
    >
      <q-card-section class="row form-inputs-content justify-between">
        <Field
          v-for="(structure, key) in structureForm"
          :key="key"
          :name="structure.name"
          v-slot="{ errorMessage, value, field }"
        >
          <q-input
            style="width: 100%"
            class="q-mb-sm"
            filled
            bottom-slots
            :dense="$q.platform.is.mobile"
            clearable
            :stack-label="structure[' stack-label']"
            v-bind="field"
            :mask="structure.mask"
            :prefix="structure.prefix"
            :type="structure.type"
            :readonly="structure.readonly"
            :counter="!!structure.maxlength"
            :maxlength="structure.maxlength"
            :model-value="value"
            :label="structure.label"
            :error-message="errorMessage"
            :error="!!errorMessage"
          >
            <template v-slot:before><q-icon :name="structure.icon" /></template>
          </q-input>
        </Field>
        <div
          class="text-grey-9 q-ml-md"
          style="font-size: 9pt"
          v-html="$t('account.social_account_alert')"
        ></div>
      </q-card-section>

      <q-card-section class="row q-mt-none q-pt-none">
        <q-btn
          color="grey"
          outline
          class="full-width bg-grey-10 q-mt-sm q-pa-sm q-pr-lg q-pl-lg"
          type="submit"
          :label="$t('global.save')"
          :loading="in_request"
          :disable="in_request"
        />
      </q-card-section>
    </Form>
  </q-intersection>
</template>

<script>
import {
  labels,
  getStructure,
  build,
  getMappedFields,
} from "@/shared/form-presets";
import { notifyError, notifySuccess } from "@/shared/helpers/notify";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import PageTitle from "@/modules/main/components/page-title";
import { Field, Form } from "vee-validate";
import { ref } from "vue";

const { useGetters, useActions } = createNamespacedHelpers("account");

export default {
  name: "SocialAccount",

  components: {
    PageTitle,
    Field,
    Form,
  },

  setup() {
    const in_request = ref(false);

    const fieldsDefinitions = [
      "facebook",
      "whatsapp",
      "instagram",
      "youtube",
      "linkedin",
      "tiktok",
      "twitter",
      "site",
    ];

    const structureForm = getStructure(fieldsDefinitions),
      schema = build(getMappedFields(fieldsDefinitions));

    const { networks } = useGetters({
        networks: "getPartnerNetworks",
      }),
      { updatePartnerNetworksAction } = useActions([
        "updatePartnerNetworksAction",
      ]);

    let initialValues = networks.value;

    const onSubmit = (values) => {
      initialValues = values;
      in_request.value = true;
      updatePartnerNetworksAction(values)
        .then(() => notifySuccess("actions.social_changed_success"))
        .catch(() => notifyError("actions.social_on_changed_error"))
        .finally(() => (in_request.value = false));
    };

    return {
      in_request,
      labels,
      schema,
      initialValues,
      structureForm,
      onSubmit,
    };
  },
};
</script>
